import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();
	const { vueApp } = nuxtApp;

	if (!config.public.sentry.dsn) {
		return;
	}

	Sentry.init({
		app: [vueApp],
		environment: process.dev ? 'development' : 'production',
		dsn: config.public.sentry.dsn,
		denyUrls: [/doubleclick\.net/],
	});
});
